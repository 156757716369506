import React, { useContext } from 'react'
import CartOverview from '../../components/cart/CartOverview'
import SEO from '../../components/generic/Seo'
import Layout from '../../components/layout/Layout'

const CartOverviewPage = (props: any) => {
  return (
    <>
      <SEO title="Dein Warenkorb" />
      <Layout>
        <CartOverview />
      </Layout>
    </>
  )
}

export default CartOverviewPage
